<!-- =========================================================================================
  Author: Hazem Ashraf
  Author URL: https://www.linkedin.com/in/hazem-ashraf-1242169b/
========================================================================================== -->

<template>
  <div id="data-list">
    <vs-popup
      classContent="popup-example"
      title="Confirm Delete"
      :active.sync="popupActive"
    >
     <validation-observer ref="observer" v-slot="{ invalid }">
       <form>
          <ValidationProvider name="password" rules="required|min:8" v-slot="{ errors }">
      <vs-input
        :danger="!!errors[0]"
        :danger-text="errors[0]"
        type="password"
        name="password"
        icon-no-border
        icon="icon icon-lock"
        icon-pack="feather"
        label-placeholder="Password"
        v-model="password"
        class="w-full mt-6"
      />
          </ValidationProvider>

      <vs-button
        :disabled="invalid"
        type="filled"
        @click="goToDeleteCity()"
        class="mb-2 mt-5"
        >Confirm</vs-button
      >
       </form>
     </validation-observer>
    </vs-popup>
    <div class="vx-card p-6">
      <div class="flex flex-wrap items-center">
        <!-- ITEMS PER PAGE -->

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->

        <vs-button class="mb-4 md:mb-0" v-can:permission="'cities_create'" @click="goToAddCity()"
          ><span style="font-weight: bold; font-size: 13px">+</span> Add
          city</vs-button
        >
        <hr />
        <vs-button color="dark" class="mb-4 md:mb-0" v-can:permission="'cities_delete'" @click="openConfirm()"
          ><span style="font-weight: bold; font-size: 13px">-</span> Delete
          selected</vs-button
        >
      </div>
      <br />

      <div class="flex flex-wrap items-center">
        <!-- ITEMS PER PAGE -->
        <div class="flex-grow">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
            >
              <span class="mr-2">
                {{
                  currentPage * paginationPageSize - (paginationPageSize - 1)
                }}
                -
                {{
                  itemsData.length - currentPage * paginationPageSize > 0
                    ? currentPage * paginationPageSize
                    : itemsData.length
                }}
                From {{ itemsData.length }}
              </span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(30)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(40)">
                <span>30</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
        <vs-input
          class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4"
          v-model="searchQuery"
          @input="updateSearchQuery"
          placeholder="Search..."
        />
        <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()"
          >Export CSV</vs-button
        >
      </div>

      <!-- AgGrid Table -->
      <ag-grid-vue
        ref="agGridTable"
        :components="components"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="itemsData"
        rowSelection="multiple"
        :animateRows="true"
        :floatingFilter="false"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl"
        @row-double-clicked="onRowDoubleClicked"
      ></ag-grid-vue>

      <vs-pagination :total="totalPages" :max="7" v-model="currentPage" />
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import vSelect from "vue-select";

import axios from "@/axios.js";
// Store Module
import moduleCitiesManagement from "@/store/get-all-cities/moduleCitiesManagement.js";

// Cell Renderer
import CellRendererLink from "../../hr-report/main-report/cell-renderer/CellRendererLink.vue";
import CellRendererStatus from "../../hr-report/main-report/cell-renderer/CellRendererStatus.vue";
// Router to navigate

import router from "@/router";
import { required, min } from "vee-validate/dist/rules";
import {
  extend,
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("eager");
// betterEager
extend("required", {
  ...required,
  message: "field can not be empty",
});

extend("min", {
  ...min,
  message: "field may not be less than {length} characters",
});

export default {
  components: {
    AgGridVue,
    vSelect,
    // Cell Renderer
    CellRendererLink,
    CellRendererStatus,
    // VALIDATION
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      page: 1,
      city: { id: null, name: null },
      popupActive: false,
      password: null,
      searchQuery: "",
      // AgGrid
      gridApi: null,
      gridOptions: {},
      defaultColDef: {
        resizable: true
      },
      columnDefs: [
        {
          headerName: "ID",
          field: "id",
          sortable: true,
          filter: true,
          checkboxSelection: true,
          width: 300
        },
        {
          headerName: "NAME",
          field: "name",
          sortable: true,
          filter: true,
          width: 1100
        }
      ],

      // Cell Renderer Components
      components: {
        CellRendererLink,
        CellRendererStatus
      }
    };
  },
  computed: {
    itemsData() {
      return this.$store.state.city.items;
    },
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 10;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      }
    }
  },
  methods: {
    // Fetch city Data
    fetchItemsData(val) {
      this.$store.dispatch("city/fetchItemsCities", val).catch(err => {
        this.$vs.notify({
          title: "Error",
          text: err,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger"
        });
      });
    },
    openConfirm() {
      this.popupActive = true;
    },
    goToAddCity() {
      router.push("/Location/Add/City");
    },
    goToDeleteCity() {
      this.$vs.loading();
      const selectedNodes = this.gridApi.getSelectedNodes();
      const selectedData = selectedNodes.map(node => node.data);
      const selectedDataStringPresentation = selectedData.map(node => node.id);

      let obj = {
        ids: selectedDataStringPresentation,
        password: this.password
      };
       this.popupActive = false;
      axios
        .delete(`${process.env.VUE_APP_BASE_URI}/api/admin/cities/delete`, {
          data: obj
        })
        .then(response => {
          this.$vs.loading.close();
          if (response.status === 200) {
            this.onRemoveSelected();
            this.$vs.notify({
              title: "success",
              text: "city is deleted",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success"
            });
           
          } else {
            this.$vs.notify({
              title: "Error",
              text: response.response.data,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger"
            });
          }
          // document.location.reload();
        })
        .catch(() => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: "Can't delete this city",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
        });
    },
    onRemoveSelected() {
      var selectedData = this.gridApi.getSelectedRows();
      //alert(JSON.stringify(selectedData));
      this.gridApi.applyTransaction({ remove: selectedData });

      //  printResult(res);
    },
    onRowDoubleClicked(event) {
     let permissions = this.$store.state.auth.permissions.length ? this.$store.state.auth.permissions  : localStorage.getItem('permissions');
        if(permissions && permissions.indexOf('cities_edit') === -1) {
          return
         }
      router.push("/Location/Update/City?SID=" + event.data.id);
    },
    setColumnFilter(column, val) {
      const filter = this.gridApi.getFilterInstance(column);
      let modelObj = null;

      if (val !== "all") {
        modelObj = { type: "equals", filter: val };
      }

      filter.setModel(modelObj);
      this.gridApi.onFilterChanged();
    },
    resetColFilters(card) {
      card.removeRefreshAnimation(100);
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;

    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector(
        ".ag-header-container"
      );
      header.style.left =
        "-" + String(Number(header.style.transform.slice(11, -3)) + 9) + "px";
    }

    this.$store.dispatch("city/fetchItemsCities").then(response => {
      this.$vs.loading.close();
      if (response.status === 404) {
        this.$vs.notify({
          title: "Error",
          text: "Make sure select the main filters ",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger"
        });
      }
    });
  },
  created() {
    if (!moduleCitiesManagement.isRegistered) {
      this.$store.registerModule("city", moduleCitiesManagement);
      moduleCitiesManagement.isRegistered = true;
    }
  }
};
</script>
